document.addEventListener('DOMContentLoaded', () => {
  handleReviewButton();
});

document.addEventListener('ConfiguratorContentLoaded', () => {
  handleReviewButton();
});

function handleReviewButton(isFirst : boolean = false) {
  const scrollToReviewButton = document.querySelector('.to-review-button');
  if(!scrollToReviewButton){
    return;
  }

  scrollToReviewButton.addEventListener('click', (e) => {
    const navTabs = document.querySelector('.nav-tabs');
    if(!navTabs) return;
    const navTabButtons = navTabs.querySelectorAll('a');

    navTabButtons.forEach((navTabButton) => {
      if(navTabButton.innerText.toLowerCase() == 'reviews' || navTabButton.innerText.toLowerCase() == 'opinie'){
        navTabButton.click();

        const tabContent = document.querySelector('#tab-content');
        const headerOffset = 120;
        const elementPosition = tabContent.getBoundingClientRect().top;
        const offsetPosition = (3 * elementPosition)/4;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    });
  });
}